import React, { ReactNode } from "react";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

type AppLayoutProps = {
    children: ReactNode;
};

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow">{children}</div>
            <Footer />
        </div>
    );
};

export default AppLayout;
