import { router } from "@inertiajs/react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import global from "../assets/global.svg";
const languages = [
    { code: "en", lang: "English" },
    { code: "el", lang: "Greek" },
];
const getDefaultLang = async () => {
    try {
        const response = await axios.get("/languages/default");
        const defaultLang = response.data.data.defaultLanguage;
        return defaultLang;
    } catch (error) {
        return "el"; // Default to Greek if there's an error
    }
};
const LanguageSelect = () => {
    const { i18n } = useTranslation();

    const [visible, setVisible] = useState(false);
    const selectedLang = languages.find((l) => l.code === i18n.language)?.lang;
    const ref = useRef();

    const showLanguages = () => setVisible(!visible);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setVisible(false);
        router.get(
            `/languages/${lng}`,
            {},
            { preserveState: true, preserveScroll: true },
        );
    };

    useEffect(() => {
        const fetchDefaultLang = async () => {
            // en is the default language so if the user hasn't selected a language yet
            const lang = await getDefaultLang();

            i18n.changeLanguage(lang);
        };

        // if((i18n.language != "en") || (i18n.language != "el")) {
        //     fetchDefaultLang();
        // }

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, [ref]);

    return (
        <div>
            <button type="button" onClick={showLanguages}>
                <img
                    src={global}
                    alt="Global"
                    className="w-6 h-6 cursor-pointer"
                />
            </button>
            {visible && (
                <ul className="py-2 absolute font-medium my-4 -ml-12 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow ">
                    {languages.map((lng) => (
                        <li key={lng.code}>
                            <button
                                title={lng.lang}
                                type="button"
                                className="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray-900  rounded-lg cursor-pointer hover:bg-gray-100 w-full"
                                onClick={() => changeLanguage(lng.code)}
                            >
                                <div className="flex flex-row w-full">
                                    <span
                                        className={`w-5 h-5 rounded-full me-3 fi fi-${lng.code === "en" ? "us" : "gr"}`}
                                    ></span>
                                    <h5>{lng.lang}</h5>
                                </div>
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default LanguageSelect;
