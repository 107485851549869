import React, {
    useState,
    useEffect,
    startTransition,
    useRef,
    Suspense,
} from "react";
import { Link, router, usePage } from "@inertiajs/react";
import logo from "../assets/Logo.svg";
import star from "../assets/star_old.svg";
import routeicon from "../assets/route-square.svg";
import useroctagon from "../assets/user-octagon.svg";
import { FiMenu, FiX } from "react-icons/fi";
import Login from "@/pages/Login";
import Register from "@/pages/Register";
import ForgotPassword from "@/pages/ForgotPassword";
import LanguageSelect from "./LanguageSelect";
import { useSelector, useDispatch } from "react-redux";
import { openModal, setCurrentRoute } from "@/store/slices/modalSlice";
import { useTranslation } from "react-i18next";

import TransLink from "@/utils/TransLink";
import { RootState } from "@/store/store";

interface MenuItem {
    url: string;
    title: string;
}

const UserPopupMenu: React.FC<{
    onClose: () => void;
    scrolled: boolean;
    isHomePage: boolean;
    isMobile: boolean;
}> = ({ onClose, scrolled, isHomePage, isMobile }) => {
    const popupRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation(["common", "validation", "glossary"]);

    const handleLogout = () => {
        router.visit("/account/logout", {
            method: "post",
            onFinish: () => {
                window.location.href = "/";
            },
        });
    };

    const isBlueBackground = scrolled || !isHomePage;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target as Node)
            ) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div
            ref={popupRef}
            className={`absolute ${isMobile ? "top-12 -left-[107px] !bg-[#070f2a]" : "top-10 -right-3"} ${isBlueBackground ? "bg-[#070f2a]" : "bg-white bg-opacity-20"} backdrop-blur-md rounded-md w-48 z-50`}
        >
            <div
                className={`absolute ${isMobile ? "-top-2 right-16 !border-b-[#070f2a] !opacity-100" : "-top-2 right-4"} w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 ${isBlueBackground ? "border-b-[#070f2a] opacity-100" : "border-b-white opacity-20"}`}
            ></div>
            <TransLink
                title={t("my_account", { ns: "glossary" })}
                url="/account/settings"
                className="block py-2 px-4 font-bold text-center hover:text-gray-300"
                onClick={onClose}
            >
                {t("my_account", { ns: "glossary" })}
            </TransLink>
            <TransLink
                url="account/plan"
                className="block py-2 px-4 font-bold text-center hover:text-gray-300"
            >
                {t("my_plan", { ns: "glossary" })}
            </TransLink>

            <button
                title={t("logout", { ns: "glossary" })}
                onClick={handleLogout}
                className="block font-bold w-full text-center py-2 px-4 hover:text-gray-300"
            >
                {t("logout", { ns: "glossary" })}
            </button>
        </div>
    );
};

const HomeHeader = () => {
    const modalOpen = useSelector((state: RootState) => state.modal.modalOpen);
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const { isAuth, menus, logo } = usePage().props;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isMobilePopupOpen, setIsMobilePopupOpen] = useState(false);

    useEffect(() => {
        document.body.style.overflow = modalOpen ? "hidden" : "auto";
    }, [modalOpen]);

    const handleOpenModal = (modalType) => {
        dispatch(openModal(modalType));
    };

    const [isHomePage, setIsHomePage] = useState(
        window.location.pathname === "/" ||
        window.location.pathname === "/en" ||
        window.location.pathname === "/el",
    );

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        document.body.style.overflow =
            menuOpen || modalOpen ? "hidden" : "auto";
    }, [menuOpen, modalOpen]);

    useEffect(() => {
        const handleOpenLoginModal = () => {
            handleOpenModal("login");
        };
        document.addEventListener("openLoginModal", handleOpenLoginModal);

        return () => {
            document.removeEventListener(
                "openLoginModal",
                handleOpenLoginModal,
            );
        };
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("modal") === "login") {
            handleOpenModal("login");
        }
    }, []);

    useEffect(() => {
        const updateIsHomePage = () => {
            setIsHomePage(
                window.location.pathname === "/" ||
                window.location.pathname === "/en" ||
                window.location.pathname === "/el",
            );
        };

        window.addEventListener("popstate", updateIsHomePage);

        const onNavigate = () => {
            updateIsHomePage();
        };

        document.addEventListener("inertia:navigate", onNavigate);

        return () => {
            window.removeEventListener("popstate", updateIsHomePage);
            document.removeEventListener("inertia:navigate", onNavigate);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        setIsPopupOpen(false);
    };

    const openLoginModal = () => {
        startTransition(() => {
            handleOpenModal("login");
        });
    };

    const openRegisterModal = () => {
        startTransition(() => {
            handleOpenModal("register");
        });
    };

    const openForgotPasswordModal = () => {
        startTransition(() => {
            handleOpenModal("forgotPassword");
        });
    };

    const closeModal = () => {
        startTransition(() => {
            handleOpenModal(null);
        });
    };

    const [isHidden, setIsHidden] = useState(
        window.location.pathname.startsWith("/password/reset"),
    );

    useEffect(() => {
        const handleLocationChange = () => {
            setIsHidden(window.location.pathname.startsWith("/password/reset"));
        };

        window.addEventListener("popstate", handleLocationChange);

        const originalPushState = window.history.pushState;
        const originalReplaceState = window.history.replaceState;

        window.history.pushState = function (...args) {
            originalPushState.apply(window.history, args);
            handleLocationChange();
        };

        window.history.replaceState = function (...args) {
            originalReplaceState.apply(window.history, args);
            handleLocationChange();
        };

        return () => {
            window.removeEventListener("popstate", handleLocationChange);
            window.history.pushState = originalPushState;
            window.history.replaceState = originalReplaceState;
        };
    }, []);

    const togglePopup = () => {


        setIsPopupOpen(!isPopupOpen);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const toggleMobilePopup = () => {
        console.log(!isMobilePopupOpen);
        setIsMobilePopupOpen(!isMobilePopupOpen);
    };

    const closeMobilePopup = () => {
        setIsMobilePopupOpen(false);
    };

    return (
        <>
            {!isHidden && !modalOpen && (
                <header
                    className={`fixed top-0 left-0 w-full text-white py-4 z-50 ${isHomePage && window.innerWidth >= 1288
                        ? scrolled
                            ? "bg-primary"
                            : "bg-white bg-opacity-10 backdrop-blur-md"
                        : "bg-primary sticky"
                        } transition-colors duration-300`}
                >
                    <div className="flex justify-between items-center px-4">
                        <TransLink url="/" className="cursor-pointer">
                            <img src={logo} alt="Logo" className="w-40 h-12" />
                        </TransLink>
                        <nav className="hidden xl:flex space-x-8 mx-5 text-center items-center">
                            {menus?.header?.map((item, index) => (
                                <TransLink
                                    key={index}
                                    url={item.url || "#"}
                                    className="hover:text-gray-300 font-bold transition"
                                    onError={() => {
                                        if (
                                            item.url === "plans/choose" &&
                                            !isAuth
                                        ) {
                                            dispatch(
                                                setCurrentRoute("plans.create"),
                                            );
                                            dispatch(openModal("login"));
                                        }
                                    }}
                                >
                                    {item.title}
                                </TransLink>
                            ))}
                        </nav>
                        <div className="hidden xl:flex space-x-4 relative mr-8">
                            <TransLink
                                url="wishlist"
                                onError={() => {
                                    dispatch(setCurrentRoute("wishable.index"));
                                    dispatch(openModal("login"));
                                }}
                            >
                                <img
                                    src={star}
                                    alt="Star"
                                    className="w-6 h-6"
                                />
                            </TransLink>

                            <TransLink url="search">
                                <img
                                    src={routeicon}
                                    alt="Route"
                                    className="w-6 h-6"
                                />
                            </TransLink>
                            <div className="relative">
                                {isAuth ? (
                                    <>
                                        <img
                                            src={useroctagon}
                                            alt="User"
                                            className="w-6 h-6 cursor-pointer"
                                            onClick={togglePopup}
                                        />
                                        {isPopupOpen && (
                                            <UserPopupMenu
                                                onClose={closePopup}
                                                scrolled={scrolled}
                                                isHomePage={isHomePage}
                                                isMobile={false}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <img
                                        src={useroctagon}
                                        alt="User"
                                        className="w-6 h-6 cursor-pointer"
                                        onClick={openLoginModal}
                                    />
                                )}
                            </div>
                            <LanguageSelect />
                        </div>
                        <div className="xl:hidden flex items-center z-50">
                            <button onClick={toggleMenu}>
                                {menuOpen ? (
                                    <FiX size={26} />
                                ) : (
                                    <FiMenu size={26} />
                                )}
                            </button>
                        </div>
                    </div>
                    <div
                        className={`fixed inset-0 bg-[#1D24CA] text-white z-40 flex flex-col items-center justify-center transition-opacity duration-300 overflow-y-scroll
                             ${menuOpen
                                ? "opacity-100 transform translate-y-0"
                                : "opacity-0 transform -translate-y-full"
                            }`}
                        style={{ pointerEvents: menuOpen ? "auto" : "none" }}
                    >
                        {/* <button
                            onClick={toggleMenu}
                            className="absolute top-6 right-4 z-50"
                        >
                            <FiX size={34} />
                        </button> */}
                        <a href="/" className="mb-8">
                            <img src={logo} alt="Logo" className="w-50 h-12" />
                        </a>
                        <div className="flex flex-col items-center justify-center ">
                            {menus?.header?.map((item, index) => (
                                <TransLink
                                    key={index}
                                    url={item.url}
                                    className="text-2xl my-4"
                                    onClick={() => setMenuOpen(false)}
                                >
                                    {item.title}
                                </TransLink>
                            ))}
                            <div className="flex space-x-6 mt-8">
                                <TransLink url="wishlist">
                                    <img
                                        src={star}
                                        alt="Star"
                                        className="w-6 h-6"
                                        onClick={() => setMenuOpen(false)}
                                    />
                                </TransLink>

                                <TransLink url="search">
                                    <img
                                        src={routeicon}
                                        alt="Route"
                                        className="w-6 h-6"
                                        onClick={() => setMenuOpen(false)}
                                    />
                                </TransLink>
                                <div className="relative">
                                    {isAuth ? (
                                        <>
                                            <img
                                                src={useroctagon}
                                                alt="User"
                                                className="w-6 h-6 cursor-pointer"
                                                onClick={toggleMobilePopup}
                                            />
                                            {isMobilePopupOpen && (
                                                <UserPopupMenu
                                                    onClose={closeMobilePopup}
                                                    scrolled={scrolled}
                                                    isHomePage={isHomePage}
                                                    isMobile={true}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <img
                                            src={useroctagon}
                                            alt="User"
                                            className="w-6 h-6 cursor-pointer"
                                            onClick={openLoginModal}
                                        />
                                    )}
                                </div>
                                <LanguageSelect />
                            </div>
                        </div>
                    </div>
                </header>
            )}

            {modalOpen && (
                <Suspense>
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50 overflow-hidden">
                        <div className="relative w-full h-full overflow-y-auto z-100">
                            <button
                                onClick={closeModal}
                                className="absolute top-2 right-2 text-2xl z-110 bg-none border-none cursor-pointer"
                            >
                                &times;
                            </button>
                            {modalOpen === "login" && (
                                <Login
                                    closeModal={closeModal}
                                    openRegisterModal={openRegisterModal}
                                    openForgotPasswordModal={
                                        openForgotPasswordModal
                                    }
                                />
                            )}
                            {modalOpen === "register" && (
                                <Register
                                    closeModal={closeModal}
                                    openLoginModal={openLoginModal}
                                />
                            )}
                            {modalOpen === "forgotPassword" && (
                                <ForgotPassword
                                    closeModal={closeModal}
                                    openLoginModal={openLoginModal}
                                />
                            )}
                        </div>
                    </div>
                </Suspense>
            )}
        </>
    );
};

export default HomeHeader;
